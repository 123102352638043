import {bool} from "yup";

declare var cadesplugin: any;
declare var checkPlugin: any;
declare var canAsync: any;
declare var PrepareCertSelector: any;
declare var CADESCOMCryptoProviderAsync: any;
declare var CADESCOMCryptoProvider: any;
import React, {useState, useEffect} from "react";
import Fetcher from "../../Common/Fetch";
import {ShowDelayedNotification} from "../../Common/functions";
import moment from "moment";

interface IPropsType {
    certName: string,
    certDate: string,
    isLoginForm: boolean
}

function Signature(props: IPropsType) {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [selectedCert, setSelectedCert] = useState(null);
    const [certChanged, setCertChanged] = useState(false);
    const [certName, setCertName] = useState(props.certName);
    const [certDate, setCertDate] = useState(props.certDate);
    const [error, setError] = useState(null);

    if (isFirstLoad) {
        setIsFirstLoad(false)
    }


    const selectCert = () => {
        cadesplugin.then(function () {
            checkPlugin(function (isPluginWorking, version) {
                if (isPluginWorking) {
                    let signer = null;

                    if (canAsync()) {
                        signer = new CADESCOMCryptoProviderAsync({showErrors: true, detached: false});

                    } else {
                        signer = new CADESCOMCryptoProvider({showErrors: true, detached: false});
                    }

                    signer.selectCertificate(PrepareCertSelector, function (hash) {
                        signer.preferredThumbprint = hash;
                        signer.checkLocal(false, function (result) {
                            if (result) {
                                if(props.isLoginForm){
                                    signer.getCertSelectorInfo(function (flag, cert) {
                                        if (flag) {
                                            Fetcher("/Account/LoginWithCertificate", {
                                                method: 'POST', headers: {'Content-Type': 'application/json'},
                                                body: JSON.stringify({ "thumbPrint": signer.preferredThumbprint })
                                            }).then(res => {
                                                return res.json()
                                            }).then(res => {
                                                if (res.returnUrl) {
                                                    setError(null);
                                                    // setCertChanged(true);
                                                    window.location = res.returnUrl;
                                                } else {
                                                    setError('Некорректный сертификат');
                                                }
                                            })
                                        } else {
                                            setError('Некорректный сертификат');
                                        }
                                    });
                                } else
                                {
                                    signer.getCertSelectorInfo(function (flag, cert) {
                                        console.log(`Flag для Signature ${flag}`);
                                        console.log(`Cert для Signature ${JSON.stringify(cert)}`);
                                        console.log(`rawData для Signature ${JSON.stringify({ "rawData": cert.Export })}`);
                                        if (flag) {
                                            

                                            Fetcher("/Certificate/ValidateCertificate", {
                                                method: 'POST', headers: {'Content-Type': 'application/json'},
                                                body: JSON.stringify({"rawData": cert.Export})
                                            }).then(res => {
                                                return res.json()
                                            })
                                                .then(res => {
                                                    console.log(`Результат валидации ${JSON.stringify(res)}`);
                                                    console.log(`Результат result для валидации ${JSON.stringify(res.result)}`)
                                                    if (res.result) {
                                                        setSelectedCert(cert);
                                                        setCertName(cert.SubjectName);
                                                        setCertDate(moment(cert.ValidToDate).format('DD.MM.YYYY HH:mm'));
                                                        setError(null);
                                                        console.log(cert);
                                                        setCertChanged(true);
                                                    } else {
                                                        setError(res.errorMessage);
                                                        console.log(error);
                                                    }
                                                })
                                        } else {
                                            setError('Некорректный сертификат');
                                            console.log(error);
                                        }
                                    });
                                }
                            } else {
                                setError('Некорректный сертификат');
                                console.log(error);
                            }
                        });
                    });
                }
            });
        });
    };


    const saveCert = () => {
        Fetcher("/ProfileApi/SaveCert", {
            method: 'POST', headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"Cert": selectedCert.Export})
        }).then(res => res.json())
            .then(res => {
                if (res.Success) {
                    setSelectedCert(null);
                    setCertChanged(false);
                    ShowDelayedNotification("Сертификат сохранен")
                }
            })
    };


    return (
        <>
            { props.isLoginForm ? 
                <div className='sertificateWrap'>
                    <h2>Сертификат</h2>
                    <div className="cont">
                        <div className="">
                            {!selectedCert && <button onClick={() => selectCert()}
                                    className="sertificateInput"> {props.isLoginForm ? 'Вход по ЭЦП' : 'Выбрать'}  </button>}
                            {error && <p className="ErrorText">{error}</p>}
                        </div>
                    </div>
                </div>
                :
                <div className='sertificateWrap'>
                    <h2>Сертификат</h2>
                    <div className={'cont'}>
                        {!selectedCert && <button onClick={() => selectCert()} className="sertificateInput"> {certName ? certName : 'Загрузить сертификат'} </button>}

                        {selectedCert && certChanged && <button onClick={() => saveCert()} className="sertificateInput"> Сохранить </button>}

                        {(certName && certDate) ?
                        <div className="sertificateDetails">
                            <p className="sertificateDate"> {certDate} <br /> {certName} </p>
                            <p>После выбора ЭЦП перезагрузите страницу</p>
                            <p>Для возможности совершать юридически значимые действия заполните данные, добавьте документы и&nbsp;отправьте администратору для подтверждения</p>
                        </div>
                        : <p className="sertificateNull">не загружен</p>
                        }
                        
                        {error && <p className="ErrorText">{error}</p>}
                    </div>
                </div>
            }
            
            
        </>
    );

}

export default Signature;